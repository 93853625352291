import React, { useEffect } from "react";
import env from "react-dotenv";
import Home_Header from "../../inc/home-header/Home_Header";
import Left_Panel from "../left-panel/Left_Panel";
import { getUserdata } from "../local-storage/Local_StorageFn";
import { useParams } from "react-router-dom";
import { add_comment_api, cheer_me_up, get_general_notifications } from "../../../services/user_apis";
import $ from 'jquery';
import { commentList } from "../../../services/user_apis";
import PostListing from "../home/PostListing";

// import { format, render, cancel, register } from 'timeago.js';
import { makeStyles } from '@material-ui/core/styles';
import { postDetails } from "../../../services/user_apis";

import { CircularProgress } from '@mui/material';
// import format from 'timeago';
import { useNavigate, Link } from 'react-router-dom'
import CommentListing from "../comment/CommentListing";
import Modal from "../../inc/modal/Modal";

const Post = ({ socketData }) => {
    const [post_list, setPost_list] = React.useState([])
    const [commentListData, setCommentList] = React.useState([])
    const [reportPostData, setReportPostData] = React.useState("")
    let [cheermeup, setCheermeup] = React.useState([]);

    const [category, setcategory] = React.useState('class');
    const [desc, setDesc] = React.useState('');
    const [circular, setcircular] = React.useState(false)
    let [reportType, setReportType] = React.useState("");
    let navigate = useNavigate();
    let { post_id } = useParams();
    const [generalNotificationData, setGeneralNotificationData] = React.useState([]);

    const post_list1 = async () => {
        setcircular(true)
        try {
            var data = { "post_id": post_id };
            const response = await postDetails(data)
            if (response.code === 1) {
                setcircular(false)
                var postList = []
                postList.push(response.data)
                setPost_list(postList)
                setcircular(false)
            }
        }
        catch (e) {
            navigate('/')
        }
        setcircular(false)
    }

    const generalNotificationList = async () => {
        let post_body = {
            user_role: user.user_role,
            classes: user.classes
        }
        const response = await get_general_notifications(post_body)
        if (response.code === 1) {
            setGeneralNotificationData(response.data)
        }
    }

    useEffect(() => {

        post_list1()
        generalNotificationList();
    }, [])

    const comment_list = async () => {
        setcircular(true)
        try {
            var data = { "post_id": post_id };
            const response = await commentList(data)
            // console.log("Comment List api call")
            // console.log("Comment Resposne", response.data);
            if (response.code === 1) {
                // console.log("Comment List api call")

                setcircular(false)
                setCommentList(response.data)
            }
            setcircular(false)
        }
        catch (e) {
            navigate('/')
        }
        setcircular(false)
    }
    useEffect(() => {
        comment_list()
    }, [])


    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 80,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('user'))
    const handleChange = (event) => {
        // console.log(event.target.value);
        setcategory(event.target.value);
    };

    const handledesc = async (e) => {
        setDesc(e.target.value)
    }

    const handleAddComment = async () => {
        // console.log("hanlde add comment..")
        try {
            if (!circular) {
                setcircular(true);
                let description = desc
                $('#post-comment1').val('');
                setDesc("")
                const comment = {
                    school_id: user.school_id,
                    post_id: post_id,
                    to_user_id: user.user_id,
                    comment: description
                }
                const response = await add_comment_api(comment)
                if (response) {
                    setcircular(false);
                    if (response.code === 1) {
                        // console.log("Comment", response);
                        // console.log(response.data.comment_count);
                        post_list[0].comment_count = response.data.comment_count;
                        post_list[0].like_count = response.data.like_count;
                        setPost_list(post_list);
                        comment_list();
                    }
                } else { setcircular(false); }
            }
        } catch (e) {
            setcircular(false)
        }
    }

    const cheerMeUp = async () => {
        const response = await cheer_me_up()
        if (response.code === 1) {
            setCheermeup(response.data)
        }
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <a href="#" className="chat-icon-mobile">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.CHAT_ICON_PNG}chat-icon.png`} />
                    </a>
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0" >
                                <div className="right-msg1 mobile-cheermeup">
                                    <div className="msg-title">
                                        Are you Feeling Sad?
                                    </div>
                                    <div className="msg-text">
                                        If you are feeling sad we can cheer up
                                        with a small surprise
                                    </div>

                                    <button
                                        className="right-btn"
                                        data-toggle="modal"
                                        data-target="#feel_Modal"
                                    >
                                        Cheer Me Up
                                    </button>

                                    <div className="feelings">
                                        <img
                                            src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}feelings.png`}
                                        />
                                    </div>
                                </div>


                                {circular && <CircularProgress />}
                                <PostListing data={post_list}
                                    callBackData={(data) => {
                                        setReportType(data.type)
                                        setReportPostData(data.reportdata)
                                    }}
                                />

                                <br></br>

                                <div className="post-comment">
                                    <input
                                        type="text"
                                        placeholder="Write something here..." value={desc} onChange={(e) => { setDesc(e.target.value) }}
                                        id="post-comment1"
                                    />
                                    <button className="post" onClick={handleAddComment}>Add Comment</button>

                                </div>
                                <br></br>
                                <div className="post-comment">
                                    <CommentListing data={commentListData}
                                        callBackData={(data) => {
                                            setReportType(data.type)
                                            setReportPostData(data.reportdata)
                                        }}
                                        handleData = {(data) => {
                                            // console.log("Datasdfasdfa is : ", data)
                                            navigate(0)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side text-left">
                                    {user.user_role === "student" &&
                                        <div className="right-msg1">
                                            <div className="msg-title">
                                                Are you Feeling Sad?
                                            </div>
                                            {cheermeup != "" &&
                                                <>
                                                    <div className="msg-text ">
                                                        We are sorry you are feeling sad. Everyone feels sad sometimes but look at the nice words one of your friends said about you.
                                                    </div>
                                                    <h6 className='mt-3'> {cheermeup?.display_user_name != "" ? cheermeup?.display_user_name : cheermeup?.username} says, {cheermeup?.cheer_up_message}</h6>
                                                </>
                                            }
                                            <button className="right-btn mt-2" onClick={() => cheerMeUp()}>Cheer Me Up</button>
                                            <div className="feelings">
                                                <img
                                                    src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}feelings.png`}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="">
                                        <div className="swiper newsSwiper">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="article-box">
                                                        <h3>
                                                            Good News Channel
                                                        </h3>
                                                        <div className="article">
                                                            <div className="article-img">
                                                                <img src={`${process.env.PUBLIC_URL + "/" + env.ARTICLE_IMG_PNG}article-img.png`} />
                                                            </div>
                                                            <div className="article-detail">
                                                                <p>
                                                                    UAE’s Hope
                                                                    Mission to
                                                                    Mars
                                                                    launches;
                                                                    astronauts
                                                                    complete
                                                                    space walk,
                                                                    prepare for
                                                                    splashdown
                                                                </p>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                        <div className="article-btn">
                                                            <a
                                                                href="#"
                                                                className="btn btn-article"
                                                            >
                                                                Read Article
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="article-box">
                                                        <h3>
                                                            Good News Channel
                                                        </h3>
                                                        <div className="article">
                                                            <div className="article-img">
                                                                <img src={`${process.env.PUBLIC_URL + "/" + env.ARTICLE_IMG_PNG}article-img.png`} />
                                                            </div>
                                                            <div className="article-detail">
                                                                <p> UAE’s Hope Mission to Mars launches; astronauts complete space walk, prepare for splashdown </p>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                        <div className="article-btn">
                                                            <a
                                                                href="#"
                                                                className="btn btn-article"
                                                            >
                                                                Read Article
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="article-box">
                                                        <h3>
                                                            Good News Channel
                                                        </h3>
                                                        <div className="article">
                                                            <div className="article-img">
                                                                <img src={`${process.env.PUBLIC_URL + "/" + env.ARTICLE_IMG_PNG}article-img.png`} />
                                                            </div>
                                                            <div className="article-detail">
                                                                <p>
                                                                    UAE’s Hope
                                                                    Mission to
                                                                    Mars
                                                                    launches;
                                                                    astronauts
                                                                    complete
                                                                    space walk,
                                                                    prepare for
                                                                    splashdown
                                                                </p>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                        <div className="article-btn">
                                                            <a
                                                                href="#"
                                                                className="btn btn-article"
                                                            >
                                                                Read Article
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {user.user_role === "student" &&

                                        /*<div className="activity-box">
                                            <Link to="/funezone" className="game-activity funzone-game" >
                                                <h4>Games</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-cartoon" >
                                                <h4>Cartoons</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-wellBeing" >
                                                <h4>Wellbeing</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-memes" >
                                                <h4>Memes</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-coding" >
                                                <h4>Coding</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-videos" >
                                                <h4>Videos</h4>
                                            </Link>
                                        </div>*/
                                        <div className="activity-box">
                                            <Link to="/funezone" state={{ activeTab: "gameTab", key: "game" }} className="game-activity funzone-game" >
                                                <h4 className={(generalNotificationData?.funzone?.game > 0) ? "notify" : ""}>Games</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.game > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            <Link to="/funezone" state={{ activeTab: "cartoonTab", key: "cartoon" }} className="game-activity funzone-cartoon" >
                                                <h4 className={(generalNotificationData?.funzone?.cartoon > 0) ? "notify" : ""}>Cartoons</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.cartoon > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            <Link to="/funezone" state={{ activeTab: "wellBeingTab", key: "well_being" }} className="game-activity funzone-wellBeing" >
                                                <h4 className={(generalNotificationData?.funzone?.well_being > 0) ? "notify" : ""}>Wellbeing</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.well_being > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            <Link to="/funezone" state={{ activeTab: "memesTab", key: "meme" }} className="game-activity funzone-memes" >
                                                <h4 className={(generalNotificationData?.funzone?.meme > 0) ? "notify" : ""}>Memes</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.meme > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            {/* <Link to="/funezone" state={{ activeTab: "codingTab", key: "coding" }} className="game-activity funzone-coding" >
                                                <h4 className={(generalNotificationData?.funzone?.coding > 0) ? "notify" : ""}>Coding</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.coding > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link> */}
                                            <Link to="/funezone" state={{ activeTab: "videoTab", key: "video" }} className="game-activity funzone-videos" >
                                                <h4 className={(generalNotificationData?.funzone?.video > 0) ? "notify" : ""}>Videos</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.video > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => data === true && setReportPostData("")} />
            </div>
        </React.Fragment >
    );
};

export default Post;
