const GLOBAL_CONSTANT = {
    // "API_URL": "https://api.beta.cybersmarties.co.nz",
    // "CHAT_API": "http://localhost:4001",
    "CHAT_API": "https://api.chat.cybersmarties.co.nz",
    // "API_URL": "https://api.cybersmarties.ie",
    // "API_URL": "https://api.cybersmarties.co.nz",
    "API_URL": "https://api.test.cybersmarties.co.nz",
    // "API_URL": "http://localhost:4000",
    // "TIME_ZONE": "Pacific/Auckland",
    // "TIME_ZONE": "Asia/Kolkata",
    "TIME_ZONE": "Europe/Dublin",
    // "API_URL": "https://api.beta.cybersmartiesusa.com",
    // "TIME_ZONE": "America/New_York",
    // "OFFLINE": "false",
    "OFFLINE": "true",
}

export default GLOBAL_CONSTANT;