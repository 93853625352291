import React from 'react'
import { CircularProgress } from '@mui/material';
import { Button } from '@material-ui/core';
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../report/Report.css'
import { report_list, update_report } from '../../../services/user_apis';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import $ from 'jquery'

const showMessage = (msg) => {
    Swal.fire({
        title: 'Cybersmarties',
        text: msg,
        confirmButtonText: 'Okay'
    })
}

export default function ReportListing() {
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem('user'))
    const [ReportStatus, setReportStatus] = React.useState("");
    const [ReportList, setReportList] = React.useState([]);
    const [reportData, setreportData] = React.useState([]);
    const [limit, setLimit] = React.useState(10);
    const [circular, setCircular] = React.useState(true);

    React.useEffect(() => {
        reportList()
    }, [limit])

    const reportList = async () => {
        const response = await report_list({ user_id: user.user_id, limit: limit })
        if (response.code === 1) {
            let filterReportData = response.data.filter((item) => item.report_status != "dismiss")
            setReportList(filterReportData)
            $("input[name='example1']:checked").prop('checked', false)
        }
        setCircular(false);
    }

    const updateReport = async (data, status) => {
        const post_body = {
            report_id: data.report_id,
            report_status: status,
            action_taken_by_user_id: user.user_id,
            action_taken_by: user.user_role,
            report_hours: document.getElementById("hoursSelection").value
        }
        if (status === "dismiss" || status === "unfreeze") {
            delete post_body.report_hours
        }

        const response = await update_report(post_body);
        if (response.code === 1) {
            reportList()
            showMessage(response.message)
        }
    }

    const filterReport = () => {
        let status = $("input[name='example1']:checked").val()
        // console.log(status)
        let filterReportData = ReportList.filter((item) => item.report_status === status)
        setReportList(filterReportData)
    }

    const updateLimit = () => {
        setCircular(true);
        setLimit(limit + 10)
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (ReportList.length < limit) {
                return <div className="no-more-activities">{t('No more activities found.')}</div>
            }
            return <Button variant="outlined" size="medium" onClick={updateLimit}>{t('See More')}</Button>
        }
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header />
                    <div className="container-fluid">
                        <section className="post-section pt-4">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <div className="my-school">
                                            <div className="report-filter text-right" data-target="#Filter_Modal" data-toggle="modal"><img src="assets/images/filter.png" /></div>
                                            <div className="students-pro">
                                                <img src="assets/images/home/course/report.svg" />
                                                <h2>{t('Reports')}</h2>
                                                <p>{t('This page lists all of the reports your users have sent in regarding inappropriate content, system abuse, spam, and so forth.')} </p>
                                            </div>
                                            <div className="school-section mt-3 ">
                                                <div className="row pt-4 ">
                                                    {ReportList && ReportList.map((item, i) => (
                                                        <div className="col-md-6" key={i}>
                                                            {/* {console.log('itmem', item)} */}
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img src={item?.to_avatar_full_path != "" ? item.to_avatar_full_path : "assets/images/s-3.png"} />
                                                                    </div>
                                                                    <div className="academic-title text-left">
                                                                        <div className="comment-right">
                                                                            <div className="more">
                                                                                <div className="more-option text-start">
                                                                                    { 
                                                                                    <div className="">
                                                                                        <a href='javaScript:' onClick={() => {
                                                                                            setReportStatus("dismiss")
                                                                                            updateReport(item, "dismiss")
                                                                                        }}>{t('Delete Report')}</a>
                                                                                    </div> }
                                                                                    <div className="">
                                                                                        <a href='javaScript:' onClick={() => {
                                                                                            setreportData(item)
                                                                                            setReportStatus("freeze")
                                                                                        }} data-toggle="modal" data-target="#Freeze_Modal">{t('Freeze Reported Student')}</a>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <a href='javaScript:' onClick={() => {
                                                                                            setreportData(item)
                                                                                            setReportStatus("freezereporter")
                                                                                        }} data-toggle="modal" data-target="#Freeze_Modal" >{t('Freeze Reporter')}</a>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <a href='javaScript:' onClick={() => {
                                                                                            setReportStatus("unfreeze")
                                                                                            updateReport(item, "unfreeze")
                                                                                        }} >{t('Unfreeze Student')}</a>
                                                                                    </div>
                                                                                </div>
                                                                                <a href="javaScript:" className="circle-nav"><i className="fas fa-ellipsis-h"></i></a>
                                                                            </div>
                                                                        </div>
                                                                        <h3>{item?.to_display_user_name != "" ? item?.to_display_user_name : item?.to_username}</h3>
                                                                        <span>{item.created_at}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="academic-body text-left">
                                                                    {item?.reported_from_role == "monitoring_admin" ? 
                                                                    <>
                                                                    <label className="posted">{t('Reported by')}: <span>{t('Monitoring Team')}</span></label>
                                                                    <br />
                                                                    <label className="posted">{t('Reported Username')}: <span>{item.to_username}</span></label>
                                                                    </>
                                                                    :
                                                                    item?.reported_from_role == "monitoring_staff" ?
                                                                    <>
                                                                    <label className="posted">{t('Reported by')}: <span>{t('Monitoring Staff')}</span></label>
                                                                    <br />
                                                                    <label className="posted">{t('Reported Username')}: <span>{item.to_username}</span></label>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <label className="posted">{t('Reported by')}: <span>{item?.from_display_user_name != "" ? item.from_display_user_name : item.from_username}</span></label>
                                                                    <br />
                                                                    <label className="posted">{t("Reporter's Username")}: <span>{item.from_username}</span></label>
                                                                    <br />
                                                                    <label className="posted">{t('Reported Username')}: <span>{item.to_username}</span></label>
                                                                    </>                                                                    
                                                                    }
                                                                    <br/>
                                                                    {item.receiver_username ? <><label className="posted">{t('Receiver Username')}: <span>{item?.receiver_username}</span></label><br/></> : ''}

                                                                    {item.sender_username ? <><label className="posted">{t('Sender Username')}: <span>{item?.sender_username}</span></label><br/></> : ''}
                                                                    
                                                                    {item.report_status ? <label className="posted">{t('Report Status')}: <span>{t(item?.report_status)}</span></label> : <label className="posted">{t('Report Status')}: <span>{t(item?.action_status)}</span></label>}
                                                                    <br/>
                                                                    <label className="posted">{t('Reason for Reporting')}: <span>{item?.report_subject}</span></label>
                                                                    <br/>
                                                                    {item.report_description ? <><label className="posted">{t('Description')}: <span>{item?.report_description}</span></label><br/></> : ''}

                                                                    <label className="posted">{t('Type of data')}: <span>{t(item?.report_type)}</span></label>
                                                                    <br/>
                                                                    <label className="posted">{t('Reported Content')} : <span>{item?.reported_content}</span></label>
                                                                    {item?.report_type=="comment"
                                                                    ?
                                                                    <>
                                                                    <br/>
                                                                    <label className="posted">{t('Post')} : <span>{item?.reported_comment_for_post}</span></label>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                    }
                                                                    <br/>
                                                                    <label className="posted">{t('Created')}: <span>{item?.created_at}</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <center>{pagignation()}</center>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="modal fade" id="Filter_Modal" tabIndex="-1" role="dialog" aria-labelledby="Filter_ModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal" role="document">
                        <div className="modal-content post-modal">
                            <div className="modal-body post-modal">
                                <div className="create-post">
                                    <div className="post-title">
                                        <h3>{t('Filter')}</h3>
                                    </div>
                                    <div className="report-form">
                                        <div className="form-group">
                                          { /*  <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={"dismiss"} id="dismiss" name="example1" />
                                                <label className="custom-control-label" for="dismiss">Dismiss</label>
                                            </div>
                                           */}
                                           <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={t("unfreeze")} id="unfreeze" name="example1" />
                                                <label className="custom-control-label" for="unfreeze">{t('Unfreeze')}</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={t("freeze")} id="freeze" name="example1" />
                                                <label className="custom-control-label" for="freeze">{t('Freeze')}</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={t("freezereporter")} id="freezereporter" name="example1" />
                                                <label className="custom-control-label" for="freezereporter">{t('Freeze Reporter')}</label>
                                            </div>
                                        </div>
                                        <div className="form-group text-center">
                                            <a href="javaScript:" className="cybersmart-btn btn-block w-100" onClick={() => filterReport()} data-dismiss="modal">{t('Apply')}</a>
                                            <a href="javaScript:" className="cybersmart-btn btn-block bg-danger w-100" onClick={() => {
                                                $("input[name='example1']:checked").prop('checked', false)
                                                reportList()
                                            }} data-dismiss="modal">{t('Reset')}</a>
                                            <a href="javaScript:" className=" btn-block cybersmart-btn text-dark bg-light w-100" data-dismiss="modal">{t('Cancel')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="Freeze_Modal" tabIndex="-1" role="dialog" aria-labelledby="Freeze_ModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal" role="document">
                        <div className="modal-content post-modal">
                            <div className="modal-body post-modal">
                                <div className="create-post">
                                    <div className="post-title">
                                        <h3>{t('Unfreeze Time')}</h3>
                                        <span>{t('Select unfreeze time below.')}</span>
                                    </div>
                                    <div className="post-form report-form">

                                        <div className="form-group">
                                            <select className="form-control text-left" id='hoursSelection'>
                                                <option>{t('Select Hours')}</option>
                                                {[...Array(9).keys()].slice(1).map((hourSelection, i) => {
                                                    return <option value={hourSelection} key={i}>{hourSelection} {t('hrs')}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <a href="javascript:" onClick={() => updateReport(reportData, ReportStatus)} className="cybersmart-btn btn-block" data-dismiss="modal">{t('Next')}</a>
                                            <a href="#" className="mt-3 text-white d-block text-center" data-dismiss="modal">{t('Cancel')}</a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
}
