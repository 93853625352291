import React, { useEffect } from 'react'
import env from 'react-dotenv'
import $ from 'jquery'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import bannerprofile from './my-profile-img/banner-profile.png'
import rectanglle3 from './my-profile-img/rectangle_3.png'
import rectanglee4 from './my-profile-img/rectangle_4.png'
import rectanggle5 from './my-profile-img/rectangle_5.png'
import rectanngle6 from './my-profile-img/rectangle_6.png'
import rectaangle7 from './my-profile-img/rectangle_7.png'
import recttangle8 from './my-profile-img/rectangle_8.png'
import { post_specific_user_api, my_friend_list, user_interest_list, delete_friend, get_user_details, cheer_me_up, get_general_notifications } from '../../../services/user_apis';
import { CircularProgress } from '@mui/material';
import { Button } from '@material-ui/core';
import PostListing from "../home/PostListing";
import { Link } from 'react-router-dom'

const My_Profile = ({ socketData }) => {
    const [ datalimit, setDatalimit ] = React.useState({ limit: 10, page: 1 });
    const [circular, setcircular] = React.useState(true)
    const [post_list_specific, setPost_list] = React.useState([])
    const [friendsData, setFriendData] = React.useState([])
    const [userData, setUserData] = React.useState()
    let [cheermeup, setCheermeup] = React.useState([]);
    const [sport, setSport] = React.useState([]);
    const [music, setMusic] = React.useState([]);
    const [reportPostData, setReportPostData] = React.useState("")
    let [reportType, setReportType] = React.useState("");
    const [uploadImagetype, setUploadImagetype] = React.useState("");
    const [generalNotificationData, setGeneralNotificationData] = React.useState([]);
    const [changeAvatar, setChangeAvatar] = React.useState(false);

    let user = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        setUserData(user)
        post_user_specific()
        myFriendList()
        //getInterests()
        generalNotificationList();
    }, [])

    useEffect(() => {
        post_user_specific();
    },[datalimit])

    const post_user_specific = async () => {
        var body = {...{ "user_id": user.user_id, "school_id": user.school_id }, ...datalimit};
        const response = await post_specific_user_api(body)
        if (response.code === 1) {
            setPost_list(response.data)
        }
        setcircular(false)
    }

    const generalNotificationList = async () => {
        let post_body = {
            user_role: user.user_role,
            classes: user.classes
        }
        const response = await get_general_notifications(post_body)
        if (response.code === 1) {
            setGeneralNotificationData(response.data)
        }
    }

    const getInterests = async () => {
        let sports = await user_interest_list({ interest_category: "sport" });
        if (sports.code === 1 && (sports.data).length > 0) {
            sports = (sports.data).filter(item => item.is_selected === 1).reduce((acc,itm) => {
                acc.push(itm.interest_name)
                return acc;
            },[])
            setSport(sports)
        }
        let musics = await user_interest_list({ interest_category: "music" });
        if (musics.code === 1 && (musics.data).length > 0) {
            musics = (musics.data).filter(item => item.is_selected === 1).reduce((acc,itm) => {
                acc.push(itm.interest_name)
                return acc;
            },[])
            setMusic(musics)
        }
    }

    const myFriendList = async () => {
        // setcircular(true)
        const response = await my_friend_list()
        // setcircular(false)
        if (response.code === 1) {
            setFriendData(response.data)
        } else if (response.code === 2) {
            setFriendData([])
        }
    }

    const removefriend = async (user_id) => {
        setcircular(true)
        const post_body = {
            delete_friend_id: user_id,
        }

        const response = await delete_friend(post_body)
        if (response.code == 1) {
            $(".chatbox").hide();
            myFriendList()
            // setTimeout(() => {
                // console.log("socket exicute")
                socketData(user_id)
            // }, 2000);
            // onClickChatMaximize();
            setcircular(false)
        }
    }

    const cheerMeUp = async () => {
        const response = await cheer_me_up()
        if (response.code === 1) {
            setCheermeup(response.data)
        }
    }

    const updateLimit = () => {
        setcircular(true)
        setDatalimit({...datalimit, "limit": Number(datalimit.limit) + 10 })
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post_list_specific.length < datalimit.limit) {
                return <div className="no-more-activities">No more activities found.</div>
            }
            return <Button variant="outlined" size="medium" onClick={updateLimit}>See More</Button>
        }
    }

    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.user_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.user_about_me, "avatar_full_path": data.avatar_full_path }
        return dataPass
    }
    
    const loadInterests = () => {
        getInterests()
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">

                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content">
                                    <div className="right-msg1 mobile-cheermeup">

                                        <div className="msg-title">
                                            Are you Feeling Sad?
                                        </div>
                                        <div className="msg-text">
                                            If you are feeling sad we can cheer up with a small surprise
                                        </div>

                                        <button className="right-btn" data-toggle="modal" data-target="#feel_Modal">Cheer Me Up</button>

                                        <div className="feelings">
                                            <img src="assets/web/feelings.png" />
                                        </div>

                                    </div>
                                    <div className='my-profile'>
                                        <div className="p-banner" style={{ backgroundimage: `url(${bannerprofile})` }}>
                                            <img src={userData?.cover_image ? userData.cover_image : "assets/web/cover2.png"} />
                                            <span className="cover-photo-change" data-target="#BGcover_Modal" data-toggle="modal" onClick={() => { if(userData.user_role === "teacher") setUploadImagetype("cover") }}>Choose different Cover Photo</span>
                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic" data-target="#ChangeAvtar_Modal" onClick={()=>setChangeAvatar(true)} data-toggle="modal">
                                                <img src={userData?.avatar_full_path} />
                                            </div>
                                        </div>
                                        <div className="p-name">
                                            {userData?.display_user_name != "" ? userData?.display_user_name : userData.username}
                                        </div>
                                        <div className="post-content">
                                            <ul className="nav nav-tabs">
                                                <li><a href="#posts" data-toggle="tab" className="active">Posts</a></li>
                                                <li><a href="#aboutmaxy" data-toggle="tab" > About Me</a></li>
                                                {user.user_role === "student" && <li><a href="#friends" data-toggle="tab" className="border-0"> {`Friends (${friendsData?.friend_list?.length > 0 ? friendsData.friend_count : "0"})`}</a></li>}
                                            </ul>
                                            <div className="profile-tab-rightside">
                                                <div className="edit-profile">
                                                    <a href="#" data-target="#ADDdetails_Modal" data-toggle="modal" onClick={loadInterests}>(Edit Profile)</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="posts">
                                            {/* {circular && <CircularProgress className="text-center" />} */}
                                            <PostListing data={post_list_specific} 
                                             callBackData={(data) => {
                                                setReportType(data.type)
                                                setReportPostData(data.reportdata)
                                            }} />
                                            <br />
                                            <center>{pagignation()}</center>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="tab-pane" id="aboutmaxy">
                                            <div className="about-profile-info">
                                                <ul>
                                                    <li>
                                                        <p>Name :</p> <b>{userData?.display_user_name}</b>
                                                    </li>
                                                    <li>
                                                        <p>Username :</p> <b>{userData?.username}</b>
                                                    </li>
                                                    <li>
                                                        <p>About Me:</p><b>{userData?.user_about_me}</b>
                                                    </li>
                                                    <li>
                                                        <p>Sports:</p><b>{sport.length > 0 ? sport.join(', ') : 'None'}</b>
                                                    </li>
                                                    <li>
                                                        <p>Music:</p><b>{music.length > 0 ? music.join(', ') : 'None'}</b>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="friends">
                                            <div className="friend-list add-friend-section">

                                                <div className="row">
                                                    {friendsData?.friend_list?.length > 0 ? friendsData.friend_list.map((item, i) =>
                                                        <div className="col-lg-4 col-md-6 col-6" key={i}>
                                                            <div className="box">
                                                                <div className="p-pic">
                                                                    <Link to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(item) }} title={item?.display_user_name}>
                                                                        <img src={item.avatar_full_path != null ? item.avatar_full_path : "assets/web/profile-1.png"} alt={item?.display_user_name != "" ? item?.display_user_name : item.username} />
                                                                    </Link>
                                                                </div>
                                                                <div className="box-content">
                                                                    <h2>
                                                                        {item?.display_user_name != "" ? item?.display_user_name : item.username}
                                                                    </h2>
                                                                    <p>
                                                                        It is so hot today
                                                                    </p>
                                                                    <div className="action d-flex justify-content-center">
                                                                        <button onClick={() => removefriend(item.user_friend_id)} className="cancel-friend">
                                                                            <img src={`${env.ADD_USER_PNG}add-user1.png`} /> Remove Friend
                                                                        </button>
                                                                        {/* <button onClick={() => removefriend(item.user_friend_id)} className="blocks-friend">
                                                                        </button> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                        :
                                                        <div className='col-lg-12 col-md-12 col-12 d-flex justify-content-center alignItems-center my-5'><p>No Data Found</p></div>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">

                                    {user.user_role === "student" &&
                                        <div className="right-msg1">
                                            <div className="msg-title">
                                                Are you Feeling Sad?
                                            </div>
                                            {cheermeup != "" &&
                                                <>
                                                    <div className="msg-text">
                                                        We are sorry you are feeling sad. Everyone feels sad sometimes but look at the nice words one of your friends said about you.
                                                    </div>
                                                    <h6 className='mt-3'> {cheermeup?.display_user_name != "" ? cheermeup?.display_user_name : cheermeup?.username} says, {cheermeup?.cheer_up_message}</h6>
                                                </>
                                            }
                                            <button className="right-btn mt-2" onClick={() => cheerMeUp()}>Cheer Me Up</button>
                                            <div className="feelings">
                                                <img
                                                    src={`${env.FEELINGS_PNG}feelings.png`}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="article-box">
                                        <h3>Good News Channel</h3>
                                        <div className="article">
                                            <div className="article-img">
                                                <img src="assets/web/article-img.png" />
                                            </div>
                                            <div className="article-detail">
                                                <p>UAE’s Hope Mission to Mars launches; astronauts complete space walk, prepare for splashdown</p>
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="article-btn">
                                            <a href="#" className="btn btn-article">Read Article</a>
                                        </div>
                                    </div> */}

                                    {user.user_role === "student" &&

                                        /*<div className="activity-box">
                                            <Link to="/funezone" className="game-activity funzone-game" >
                                                <h4>Games</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-cartoon" >
                                                <h4>Cartoons</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-wellBeing" >
                                                <h4>Wellbeing</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-memes" >
                                                <h4>Memes</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-coding" >
                                                <h4>Coding</h4>
                                            </Link>
                                            <Link to="/funezone" className="game-activity funzone-videos" >
                                                <h4>Videos</h4>
                                            </Link>
                                        </div>*/
                                        <div className="activity-box">
                                            <Link to="/funezone" state={{ activeTab: "gameTab", key: "game" }} className="game-activity funzone-game" >
                                                <h4 className={(generalNotificationData?.funzone?.game > 0) ? "notify" : ""}>Games</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.game > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            <Link to="/funezone" state={{ activeTab: "cartoonTab", key: "cartoon" }} className="game-activity funzone-cartoon" >
                                                <h4 className={(generalNotificationData?.funzone?.cartoon > 0) ? "notify" : ""}>Cartoons</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.cartoon > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            <Link to="/funezone" state={{ activeTab: "wellBeingTab", key: "well_being" }} className="game-activity funzone-wellBeing" >
                                                <h4 className={(generalNotificationData?.funzone?.well_being > 0) ? "notify" : ""}>Wellbeing</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.well_being > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            <Link to="/funezone" state={{ activeTab: "memesTab", key: "meme" }} className="game-activity funzone-memes" >
                                                <h4 className={(generalNotificationData?.funzone?.meme > 0) ? "notify" : ""}>Memes</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.meme > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                            {/* <Link to="/funezone" state={{ activeTab: "codingTab", key: "coding" }} className="game-activity funzone-coding" >
                                                <h4 className={(generalNotificationData?.funzone?.coding > 0) ? "notify" : ""}>Coding</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.coding > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link> */}
                                            <Link to="/funezone" state={{ activeTab: "videoTab", key: "video" }} className="game-activity funzone-videos" >
                                                <h4 className={(generalNotificationData?.funzone?.video > 0) ? "notify" : ""}>Videos</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.video > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link>
                                        </div>
                                    }
                                    {/* <div className="chat-with-friend">
                                        <div className="chat-head">
                                            <h3>Chat with Friends (5)</h3>
                                        </div>
                                        <div className="chat-body">
                                            <a href="#">
                                                <img src="assets/web/pro-img.png" />
                                                Jennifer
                                            </a>
                                            <a href="#">
                                                <img src="assets/web/pro-img.png" />
                                                Cathy
                                            </a>
                                            <a href="#">
                                                <img src="assets/web/pro-img.png" />
                                                Courtney
                                            </a>
                                            <a href="#">
                                                <img src="assets/web/pro-img.png" />
                                                Jennifer
                                            </a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <Modal report_type={reportType} report_data={reportPostData} changeAvatar={changeAvatar} isUpdate={(data) => (data === true && (setUserData(JSON.parse(localStorage.getItem('user')), post_user_specific()), setReportPostData("")))} uploadImagetype={uploadImagetype} />

        </React.Fragment>
    )
}

export default My_Profile



