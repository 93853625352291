import React from 'react'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { Button } from '@material-ui/core';
import Home_Header from '../../inc/home-header/Home_Header'
import { notification_list, notification_change_status , mark_as_read,survey_details,submit_survey, friend_reason_list } from '../../../services/user_apis';
import { bgcolor } from '@mui/system';
import env from "react-dotenv";
import { useNavigate, Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import $ from 'jquery'
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'

const Notification = ({ socketData }) => {

    const { t } = useTranslation();
    const [notificationlist, setNotificationList] = React.useState([]);
    const [socket, setSocket] = React.useState(null);
    const [limit, setLimit] = React.useState(10);
    const [circular, setCircular] = React.useState(false)
    const [friendRequestReason, setFriendRequestReason] = React.useState(false);
    const [friendreasonList, setFriendReasonList] = React.useState([])
    const [sendRequestData, setSendRequestData] = React.useState(null)
    const user = JSON.parse(localStorage.getItem('user'))
    let navigate = useNavigate();

    React.useEffect(() => {
        myNotification()
    }, [limit])

    const friend_list_reason_api = async () => {
        const response = await friend_reason_list()
        if (response.code === 1) {
            setFriendReasonList(response.data)
            // console.log("friend reason list   ====> ", response.data)
        }

    }

    React.useEffect(()=>{
        if(friendRequestReason) friend_list_reason_api()
    },[friendRequestReason])


    const myNotification = async () => {
        setCircular(true);
        const response = await notification_list({ limit: limit })
        // console.log(response);
        if (response.code === 1) {
            setNotificationList(response.data)
        } else if (response.code === 2) {
            setNotificationList([])
        }
        setCircular(false);
    }

    const notificationChangeStatus = async (userid, status, notificatonid) => {
        const user = JSON.parse(localStorage.getItem('user'))
        const post_body = {
            from_user_id: userid,
            friend_status: status,
            notification_id: notificatonid,
            school_id: user.school_id
        }
        const response = await notification_change_status(post_body)
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
        if (status === "accepted") {
            setTimeout(() => {
                // console.log("socket exicute")
                socketData(userid)
            }, 2000);
        }
    }

    const redirectNotification = (item) => {
        // console.log("redirect notification", item, user);
        if (item.action === "add_announcement") {
            navigate("/my-class")
        } else if (item.action === "add_homework") {
            navigate("/my-homework")
        } else if (item.action === "post_comment") {
            navigate(`/comment/${item.data.post_id}`)
        }else if(item.action === "post"){
            navigate(`/comment/${item.data.post_id}`)
        }else if (item.action=="online_class"){
            navigate("/my-homework")
        }else if(item.action =="homework_submission"){
            if (user.user_role == "teacher") navigate("/my-homework-teacher")
            if (user.user_role == "student") navigate("/homeworkDetails")
        }
        else if(item.action === "survey"){
            callAPISurveyCheck(item)
        }else if(item.action=="add_report"){
            if( user.user_role === "teacher"){
                navigate("/reportAction")
            }
        }
        else if(item.action=="offline_chat"){
            onClickClariaBot();
        }
    }

    const onClickClariaBot = (event) => {
        $('.clarie').removeClass('clarie-active')
        $('.chat-with-friend').addClass('chat-with-desk-friend-active')
    }

    const callAPISurveyCheck= async (item) => {
        const response = await survey_details({"survey_id":item.action_id})
        if (response.code === 1) {
            showSurveyPopup(response.data)
        }
        
    }

    const showSurveyPopup = (item)=>{
        Swal.fire({
            title: item.title,
            text: item.description,
            input: 'radio',
            inputOptions: {"1":item.option1,"2":item.option2,"3":item.option3,"4":item.option4},
            inputValidator: (value) => {
              if (!value) {
                return 'You need to choose something!'
              }
            }
          }).then((result) => {
            setSurveyUser(item,result.value)
          })
    }

    const setSurveyUser = async(item,value)=>{
        var useranswer=item.option4
        if(value === 1){
            useranswer=item.option1
        }else if(value === 2) {
            useranswer=item.option2
        }
        else if(value === 3) {
            useranswer=item.option3
        }                
        var userid=localStorage.getItem("userId");

        const response = await submit_survey({"surevy_id":item.id,"user_id":userid,"user_answer":useranswer})
        if (response.code === 1) {
            Swal.fire({
                title: 'Cybersmarties',
                text: response.message,
                confirmButtonText: 'Okay'
              })
        }
    }

    const callMarkAsAllRead = async () => {
        const response = await mark_as_read()
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
    }

    const callMarkSingleRead = async (id) => {
        const response = await mark_as_read({ notification_id : id})
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (notificationlist.length < limit) {
                return <div className="no-more-activities">{t('No more notificatons found.')}</div>
            }
            return <Button variant="outlined" size="medium" onClick={() => setLimit(limit+10)}>{t('See More')}</Button>
        }
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />

                    <div className="container-fluid py-3">

                        <div className="row">
                            <div className="col-md-6">
                                <div className="accordion notification-accoridan" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0 ">
                                                <button className="btn btn-recent panel-title" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    {t('Recent Updates')}
                                                </button>
                                                <button className="btn btn-recent btn-web" type="button">
                                                    {t('Recent Updates')}
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                {notificationlist.length > 0 ?
                                                    notificationlist.map((item, i) => {
                                                        return (
                                                            item.action != "friend_request" ?
                                                                <div className={`card notification-card-homework ${item.is_unread === 1 ? "unread" : "read"}   my-3 px-5 py-2 mx-auto`} key={i}>
                                                                    {
                                                                        item.action === "add_homework" ?
                                                                        <div className="card-body row alignItems-center" onClick={() => callMarkSingleRead(item.notification_id)}>
                                                                            <img src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                            <div className='text-left  mx-2'>
                                                                                <p className="card-text m-0">{`${item.notification_title} ${t('from')} ${item.sender_detail.display_user_name != "" ? item.sender_detail.display_user_name : item.sender_detail.username} `}</p>
                                                                                <p className="card-text m-0">{`${item.notification_message}`}</p>
                                                                                <p><Link className="viewbutton" to={"/homeworkDetails"} state={{ homworkData: item.data }}>{t('View')}</Link></p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        item.action === "homework_submission" ?
                                                                        <div className="card-body row alignItems-center" onClick={() => callMarkSingleRead(item.notification_id)}>
                                                                            <img src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                            <div className='text-left  mx-2'>
                                                                                <p className="card-text m-0">{`${item.notification_title} ${t('from')} ${item.sender_detail.display_user_name != "" ? item.sender_detail.display_user_name : item.sender_detail.username} `}</p>
                                                                                <p className="card-text m-0">{`${item.notification_message}`}</p>
                                                                                <p><Link className="viewbutton" to={user.user_role==='student' ? "/homeworkDetails" : "/home-work-submit-details"} state={{ homworkData: item.data }}>{t('View')}</Link></p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        item.action === "online_class" ?
                                                                        <div className="card-body row alignItems-center" onClick={() => callMarkSingleRead(item.notification_id)}>
                                                                            <img src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                            <div className='text-left  mx-2'>
                                                                                <p className="card-text m-0">{`${item.notification_title} ${t('from')} ${item.sender_detail.display_user_name != "" ? item.sender_detail.display_user_name : item.sender_detail.username} `}</p>
                                                                                <p className="card-text m-0">{`${item.notification_message}`}</p>
                                                                                <p><Link className="viewbutton" to={"/my-homework"} state={{ homworkData: item.data }}>{t('View')}</Link></p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div onClick={() => {callMarkSingleRead(item.notification_id); redirectNotification(item)}} state={{ homworkData: item.data }} className="card-body row alignItems-center">
                                                                            <img src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                            <div className='text-left  mx-2'>
                                                                                <p className="card-text m-0">{`${item.sender_detail.display_user_name != "" ? item.sender_detail.display_user_name : item.sender_detail.username} ${t('has')} ${item.notification_title}`}</p>
                                                                                <p className="card-text m-0">{`${item.notification_message}`}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                : null
                                                        )
                                                    }
                                                    )
                                                    :
                                                    <div className="non-content text-center">
                                                        <img src="assets/web/bell.png" />
                                                        <p>
                                                            {t('No New Notifications')}
                                                        </p>
                                                        <p>
                                                            {t('There are no notifications at this moment')}
                                                        </p>
                                                    </div>
                                                }
                                                {notificationlist.length > 0 ?
                                                    <div className="view-all m-1">
                                                        <div>
                                                            <a href='javaScript:' className='text-center' onClick={() => callMarkAsAllRead()}>
                                                                {t('Mark All As Read')}
                                                            </a>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {user.user_role === "student" &&
                                <div className="col-md-6">
                                    <div className="accordion notification-accoridan" id="accordionExample">
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="mb-0 ">
                                                    <button className="btn btn-recent panel-title" type="button" data-toggle="collapse"
                                                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        {t('New Friend Requests')}
                                                    </button>
                                                    <button className="btn btn-recent btn-web" type="button">
                                                        {t('New Friend Requests')}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div className="card-body">
                                                    {notificationlist.length > 0 ?
                                                        notificationlist.map((item, i) => {
                                                            return (
                                                                item.action === "friend_request" ?
                                                                    <div className="card notification-card   my-3 px-5 py-2 mx-auto" key={i}>
                                                                        <div className="card-body row alignItems-center">
                                                                            <img src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                            <div className='w-75 text-left mx-2'>
                                                                                <p className="card-text m-0">{`${item.notification_message}`}</p>
                                                                                {item.data.status === "accepted" ?
                                                                                    <p className='m-0'>{t('Request Accepted')}</p>
                                                                                    :
                                                                                    <>
                                                                                        <button onClick={() => {setFriendRequestReason(true); setSendRequestData(item)}} className="btn btn-success mx-1" data-target="#friend-RequestModal" data-toggle="modal">{t('Accept')}</button>
                                                                                        <button onClick={() => {setFriendRequestReason(true); notificationChangeStatus(item.data.from_user_id, "rejected", item.notification_id)}} className="btn btn-danger mx-1">{t('Reject')}</button>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            )
                                                        }
                                                        )
                                                        :
                                                        <div className="non-content text-center">
                                                            <img src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}friend.png`} />
                                                            <p>
                                                                {t('No New Friend Requests')}
                                                            </p>
                                                            <p>
                                                                {t('There are no new friend requests at this moment')}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        <br/>
                        {pagignation()}
                    </div>
                </div>


            </div>
            {sendRequestData ?
                <Modal socketData={socketData} isAcceptRequest={true} friend_reason_list={friendreasonList} send_friend_request_data={sendRequestData} isUpdate={(data) => {setSendRequestData(null); data === true ? myNotification(): setSendRequestData(null)}} onPrimaryButtonClick={()=> {setSendRequestData(null); myNotification()}}/>
            :null}
        </React.Fragment>
    )
}

export default Notification
